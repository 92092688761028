import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'uikit/box';
import { Typography } from 'uikit/typography';
import { TextInput } from 'uikit/textInput';

function PortalTextInputField({ type, title, help, value, onChange, error, marginBottom }) {
    return (
        <Box marginBottom={marginBottom}>
            <Typography fontStyle="small" theme="light" fontWeight="bold" spacing="micro">
                {title}
            </Typography>
            {help && (
                <Typography
                    fontStyle="x-small"
                    theme="light-secondary"
                    fontWeight="regular"
                    spacing="micro"
                >
                    {help}
                </Typography>
            )}
            <TextInput
                type={type}
                value={value}
                onChange={onChange}
                shouldSpellcheck={false}
                hasError={!!error}
                autoComplete="off"
            />
            {error && (
                <Box marginTop="micro">
                    <Typography
                        fontStyle="x-small"
                        theme="warning"
                        fontWeight="bold"
                        spacing="micro"
                    >
                        {error}
                    </Typography>
                </Box>
            )}
        </Box>
    );
}

PortalTextInputField.propTypes = {
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    help: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
    marginBottom: PropTypes.string,
};

PortalTextInputField.defaultProps = {
    type: 'text',
    marginBottom: 'regular',
};

export default PortalTextInputField;
