import isEmail from 'validator/lib/isEmail';

/**
 * Get whether the signup model has any errors.
 * @param {object} model
 */

const checkProperty = {
    email: (value, model, data, errors) => {
        let error;
        if (!isEmail(value)) {
            error = "This doesn't look like a valid email address.";
        }
        data.email = value;
        if (error) errors.email = error;
    },
    password: (value, model, data, errors) => {
        let error;
        if (!value) {
            error = 'You need to set your password here.';
        }
        data.password = value;
        if (error) errors.password = error;
    },
};

function getLoginData(model) {
    const data = {};
    const errors = {};

    _.each(model, (value, key) => {
        checkProperty[key](value, model, data, errors);
    });

    return { data, errors };
}

export default getLoginData;
