import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

/**
 * @param {React.PropsWithChildren<{
 *  component: string | React.ReactElement
 *  className?: string,
 *  testId?: string,
 *  style?: object,
 *  isFullWidth?: boolean
 *  isFullHeight?: boolean
 *  width?: 'auto' | 'full' | 'screen' | 'min' | 'max' | 'fit' | 'half' | 'one-third' | 'two-thirds' | 'one-quarter' | 'two-quarters' | 'three-quarters' | 'one-fifth' | 'two-fifths' | 'three-fifths' | 'four-fifths' | 'one-sixth' | 'two-sixths' | 'three-sixths' | 'four-sixths' | 'five-sixths'
 *  height?: 'auto' | 'full' | 'screen' | 'min' | 'max' | 'fit' | 'half' | 'one-third' | 'two-thirds' | 'one-quarter' | 'two-quarters' | 'three-quarters' | 'one-fifth' | 'two-fifths' | 'three-fifths' | 'four-fifths' | 'one-sixth' | 'two-sixths' | 'three-sixths' | 'four-sixths' | 'five-sixths'
 *  display?: 'block' | 'inline' | 'flex',
 *  position?: 'static' | 'fixed' | 'absolute' | 'relative' | 'sticky'
 *  top?: '0' | 'half' | 'negative-half' | 'full' | 'negative-full'
 *  bottom?: '0' | 'half' | 'negative-half' | 'full' | 'negative-full'
 *  left?: '0' | 'half' | 'negative-half' | 'full' | 'negative-full'
 *  right?: '0' | 'half' | 'negative-half' | 'full' | 'negative-full'
 *  zIndex?: '0' | '1' | '2' | '3' | '4' | '5' | '10' | '20' | '30' | '40' | '50' | 'max-1' | 'max-2' | 'max-3' | 'auto'
 *  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'
 *  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly'
 *  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
 *  background?: 'light' | 'light-secondary' | 'light-tertiary' | 'dark' | 'dark-secondary' | 'primary' | 'primary-darker' | 'primary-darkest' | 'primary-lighter' | 'primary-lightest' | 'secondary' | 'secondary-darker' | 'tertiary' | 'tertiary-darker' | 'warning' | 'warning-darker' | 'success' | 'success-darker'
 *  border?: 'light' | 'light-secondary' | 'dark' | 'dark-secondary'
 *  borderRadius?: 'regular' | 'large'
 *  boxShadow?: 'small' | 'regular' | 'large'
 *  overflowX?: 'auto' | 'scroll' | 'hidden' | 'visible'
 *  overflowY?: 'auto' | 'scroll' | 'hidden' | 'visible'
 *  flex?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | 'auto' | 'initial' | 'none'
 *  order?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | 'first' | 'last' | 'none'
 *  grow?: '0' | '1'
 *  shrink?: '0' | '1'
 *  alignSelf?: 'auto' | 'start' | 'end' | 'center' | 'stretch' | 'baseline'
 *  opacity?: '0' | '5' | '10' | '20' | '25' | '30' | '40' | '50' | '60' | '70' | '75' | '80' | '90' | '95' | '100'
 *  margin?: 'auto' | 'none' | 'micro' | 'x-small' | 'm-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  marginTop?: 'auto' |'micro' | 'x-small' | 'm-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  marginBottom?: 'auto' |'micro' | 'x-small' | 'm-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  marginLeft?: 'auto' | 'micro' | 'x-small' | 'm-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  marginRight?: 'auto' |'micro' | 'x-small' | 'm-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  padding?: 'micro' | 'none' | 'x-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  paddingTop?: 'micro' | 'x-small' | 'm-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  paddingBottom?: 'micro' | 'x-small' | 'm-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  paddingLeft?: 'micro' | 'x-small' | 'm-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  paddingRight?: 'micro' | 'x-small' | 'm-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  mediumDisplay?: 'block' | 'inline' | 'flex',
 *  mediumAlignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'
 *  mediumJustifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly'
 *  mediumFlexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
 *  mediumBackground?: 'light' | 'light-secondary' | 'light-tertiary' | 'dark' | 'dark-secondary' | 'primary'
 *  mediumBorder?: 'light' | 'light-secondary' | 'dark' | 'dark-secondary'
 *  mediumBorderRadius?: 'regular' | 'large'
 *  mediumMargin?: 'auto' | 'none' | 'micro' | 'x-small' | 'm-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  mediumMarginTop?: 'auto' |'micro' | 'x-small' | 'm-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  mediumMarginBottom?: 'auto' |'micro' | 'x-small' | 'm-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  mediumMarginLeft?: 'auto' | 'micro' | 'x-small' | 'm-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  mediumMarginRight?: 'auto' |'micro' | 'x-small' | 'm-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  mediumPadding?: 'micro' | 'none' | 'x-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  mediumPaddingTop?: 'micro' | 'x-small' | 'm-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  mediumPaddingBottom?: 'micro' | 'x-small' | 'm-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  mediumPaddingLeft?: 'micro' | 'x-small' | 'm-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  mediumPaddingRight?: 'micro' | 'x-small' | 'm-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  smallDisplay?: 'block' | 'inline' | 'flex',
 *  smallAlignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'
 *  smallJustifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly'
 *  smallFlexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
 *  smallBackground?: 'light' | 'light-secondary' | 'light-tertiary' | 'dark' | 'dark-secondary' | 'primary'
 *  smallBorder?: 'light' | 'light-secondary' | 'dark' | 'dark-secondary'
 *  smallBorderRadius?: 'regular' | 'large'
 *  smallMargin?: 'auto' | 'none' | 'micro' | 'x-small' | 'm-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  smallMarginTop?: 'auto' |'micro' | 'x-small' | 'm-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  smallMarginBottom?: 'auto' |'micro' | 'x-small' | 'm-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  smallMarginLeft?: 'auto' | 'micro' | 'x-small' | 'm-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  smallMarginRight?: 'auto' |'micro' | 'x-small' | 'm-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  smallPadding?: 'micro' | 'none' | 'x-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  smallPaddingTop?: 'micro' | 'x-small' | 'm-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  smallPaddingBottom?: 'micro' | 'x-small' | 'm-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  smallPaddingLeft?: 'micro' | 'x-small' | 'm-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  smallPaddingRight?: 'micro' | 'x-small' | 'm-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  rest?:
 * }>} props
 */
function Box({
    component: Component,
    children,
    className,
    testId,
    style,
    isFullWidth,
    isFullHeight,
    width,
    height,
    display,
    position,
    top,
    bottom,
    left,
    right,
    zIndex,
    alignItems,
    justifyContent,
    flexDirection,
    background,
    border,
    borderRadius,
    boxShadow,
    overflowX,
    overflowY,
    flex,
    order,
    grow,
    shrink,
    alignSelf,
    opacity,
    margin,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    padding,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    mediumDisplay,
    mediumAlignItems,
    mediumJustifyContent,
    mediumFlexDirection,
    mediumBackground,
    mediumBorder,
    mediumBorderRadius,
    mediumMargin,
    mediumMarginTop,
    mediumMarginBottom,
    mediumMarginLeft,
    mediumMarginRight,
    mediumPadding,
    mediumPaddingTop,
    mediumPaddingBottom,
    mediumPaddingLeft,
    mediumPaddingRight,
    smallDisplay,
    smallAlignItems,
    smallJustifyContent,
    smallFlexDirection,
    smallBackground,
    smallBorder,
    smallBorderRadius,
    smallMargin,
    smallMarginTop,
    smallMarginBottom,
    smallMarginLeft,
    smallMarginRight,
    smallPadding,
    smallPaddingTop,
    smallPaddingBottom,
    smallPaddingLeft,
    smallPaddingRight,
    ...rest
}) {
    return (
        <Component
            className={classnames(
                'box',
                className,
                isFullWidth && `is-full-width`,
                isFullHeight && `is-full-height`,
                width && `is-width-${width}`,
                height && `is-height-${height}`,
                display && `is-display-${display}`,
                position && `is-position-${position}`,
                top && `is-top-${top}`,
                bottom && `is-bottom-${bottom}`,
                left && `is-left-${left}`,
                right && `is-right-${right}`,
                zIndex && `is-z-index-${zIndex}`,
                alignItems && `is-align-items-${alignItems}`,
                justifyContent && `is-justify-content-${justifyContent}`,
                flexDirection && `is-flex-direction-${flexDirection}`,
                background && `is-background-${background}`,
                border && `is-border-${border}`,
                borderRadius && `is-border-radius-${borderRadius}`,
                boxShadow && `is-box-shadow-${boxShadow}`,
                overflowX && `is-overflow-x-${overflowX}`,
                overflowY && `is-overflow-y-${overflowY}`,
                flex && `is-flex-${flex}`,
                order && `is-order-${order}`,
                grow && `is-grow-${grow}`,
                shrink && `is-shrink-${shrink}`,
                alignSelf && `is-align-self-${alignSelf}`,
                opacity && `is-opacity-${opacity}`,
                margin && `is-margin-${margin}`,
                marginTop && `is-margin-top-${marginTop}`,
                marginBottom && `is-margin-bottom-${marginBottom}`,
                marginLeft && `is-margin-left-${marginLeft}`,
                marginRight && `is-margin-right-${marginRight}`,
                padding && `is-padding-${padding}`,
                paddingTop && `is-padding-top-${paddingTop}`,
                paddingBottom && `is-padding-bottom-${paddingBottom}`,
                paddingLeft && `is-padding-left-${paddingLeft}`,
                paddingRight && `is-padding-right-${paddingRight}`,
                mediumDisplay && `is-medium-display-${mediumDisplay}`,
                mediumAlignItems && `is-medium-align-items-${mediumAlignItems}`,
                mediumJustifyContent && `is-medium-justify-content-${mediumJustifyContent}`,
                mediumFlexDirection && `is-medium-flex-direction-${mediumFlexDirection}`,
                mediumBackground && `is-medium-background-${mediumBackground}`,
                mediumBorder && `is-medium-border-${mediumBorder}`,
                mediumBorderRadius && `is-medium-border-radius-${mediumBorderRadius}`,
                mediumMargin && `is-medium-margin-${mediumMargin}`,
                mediumMarginTop && `is-medium-margin-top-${mediumMarginTop}`,
                mediumMarginBottom && `is-medium-margin-bottom-${mediumMarginBottom}`,
                mediumMarginLeft && `is-medium-margin-left-${mediumMarginLeft}`,
                mediumMarginRight && `is-medium-margin-right-${mediumMarginRight}`,
                mediumPadding && `is-medium-padding-${mediumPadding}`,
                mediumPaddingTop && `is-medium-padding-top-${mediumPaddingTop}`,
                mediumPaddingBottom && `is-medium-padding-bottom-${mediumPaddingBottom}`,
                mediumPaddingLeft && `is-medium-padding-left-${mediumPaddingLeft}`,
                mediumPaddingRight && `is-medium-padding-right-${mediumPaddingRight}`,
                smallDisplay && `is-small-display-${smallDisplay}`,
                smallAlignItems && `is-small-align-items-${smallAlignItems}`,
                smallJustifyContent && `is-small-justify-content-${smallJustifyContent}`,
                smallFlexDirection && `is-small-flex-direction-${smallFlexDirection}`,
                smallBackground && `is-small-background-${smallBackground}`,
                smallBorder && `is-small-border-${smallBorder}`,
                smallBorderRadius && `is-small-border-radius-${smallBorderRadius}`,
                smallMargin && `is-small-margin-${smallMargin}`,
                smallMarginTop && `is-small-margin-top-${smallMarginTop}`,
                smallMarginBottom && `is-small-margin-bottom-${smallMarginBottom}`,
                smallMarginLeft && `is-small-margin-left-${smallMarginLeft}`,
                smallMarginRight && `is-small-margin-right-${smallMarginRight}`,
                smallPadding && `is-small-padding-${smallPadding}`,
                smallPaddingTop && `is-small-padding-top-${smallPaddingTop}`,
                smallPaddingBottom && `is-small-padding-bottom-${smallPaddingBottom}`,
                smallPaddingLeft && `is-small-padding-left-${smallPaddingLeft}`,
                smallPaddingRight && `is-small-padding-right-${smallPaddingRight}`
            )}
            style={style}
            data-testid={testId}
            {...rest}
        >
            {children}
        </Component>
    );
}

Box.propTypes = {
    component: PropTypes.elementType.isRequired,
    className: PropTypes.string,
    testId: PropTypes.string,
    style: PropTypes.object,
    isFullWidth: PropTypes.bool,
    isFullHeight: PropTypes.bool,
    width: PropTypes.oneOf([
        'auto',
        'full',
        'screen',
        'min',
        'max',
        'fit',
        'half',
        'one-third',
        'two-thirds',
        'one-quarter',
        'two-quarters',
        'three-quarters',
        'one-fifth',
        'two-fifths',
        'three-fifths',
        'four-fifths',
        'one-sixth',
        'two-sixths',
        'three-sixths',
        'four-sixths',
        'five-sixths',
    ]),
    height: PropTypes.oneOf([
        'auto',
        'full',
        'screen',
        'min',
        'max',
        'fit',
        'half',
        'one-third',
        'two-thirds',
        'one-quarter',
        'two-quarters',
        'three-quarters',
        'one-fifth',
        'two-fifths',
        'three-fifths',
        'four-fifths',
        'one-sixth',
        'two-sixths',
        'three-sixths',
        'four-sixths',
        'five-sixths',
    ]),
    display: PropTypes.oneOf(['block', 'inline', 'flex']),
    position: PropTypes.oneOf(['static', 'fixed', 'absolute', 'relative', 'sticky']),
    top: PropTypes.oneOf(['0', 'half', 'negative-half', 'full', 'negative-full']),
    bottom: PropTypes.oneOf(['0', 'half', 'negative-half', 'full', 'negative-full']),
    left: PropTypes.oneOf(['0', 'half', 'negative-half', 'full', 'negative-full']),
    right: PropTypes.oneOf(['0', 'half', 'negative-half', 'full', 'negative-full']),
    zIndex: PropTypes.oneOf([
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '10',
        '20',
        '30',
        '40',
        '50',
        'max-1',
        'max-2',
        'max-3',
        'auto',
    ]),
    alignItems: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'baseline', 'stretch']),
    justifyContent: PropTypes.oneOf([
        'flex-start',
        'flex-end',
        'center',
        'space-between',
        'space-around',
        'space-evenly',
    ]),
    flexDirection: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
    background: PropTypes.oneOf([
        'light',
        'light-secondary',
        'light-tertiary',
        'dark',
        'dark-secondary',
        'primary',
        'primary-darker',
        'primary-darkest',
        'primary-lighter',
        'primary-lightest',
        'secondary',
        'secondary-darker',
        'tertiary',
        'tertiary-darker',
        'warning',
        'warning-darker',
        'success',
        'success-darker',
    ]),
    border: PropTypes.oneOf(['light', 'light-secondary', 'dark', 'dark-secondary']),
    borderRadius: PropTypes.oneOf(['regular', 'large']),
    boxShadow: PropTypes.oneOf(['small', 'regular', 'large']),
    margin: PropTypes.oneOf([
        'auto',
        'none',
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    marginTop: PropTypes.oneOf([
        'auto',
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    marginBottom: PropTypes.oneOf([
        'auto',
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    marginLeft: PropTypes.oneOf([
        'auto',
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    marginRight: PropTypes.oneOf([
        'auto',
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    padding: PropTypes.oneOf([
        'none',
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    paddingTop: PropTypes.oneOf([
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    paddingBottom: PropTypes.oneOf([
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    paddingLeft: PropTypes.oneOf([
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    paddingRight: PropTypes.oneOf([
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    mediumDisplay: PropTypes.oneOf(['block', 'inline', 'flex']),
    mediumAlignItems: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'baseline', 'stretch']),
    mediumJustifyContent: PropTypes.oneOf([
        'flex-start',
        'flex-end',
        'center',
        'space-between',
        'space-around',
        'space-evenly',
    ]),
    mediumFlexDirection: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
    mediumBackground: PropTypes.oneOf([
        'light',
        'light-secondary',
        'light-tertiary',
        'dark',
        'dark-secondary',
        'primary',
    ]),
    mediumBorder: PropTypes.oneOf(['light', 'light-secondary', 'dark', 'dark-secondary']),
    mediumBorderRadius: PropTypes.oneOf(['regular', 'large']),
    mediumMargin: PropTypes.oneOf([
        'auto',
        'none',
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    mediumMarginTop: PropTypes.oneOf([
        'auto',
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    mediumMarginBottom: PropTypes.oneOf([
        'auto',
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    mediumMarginLeft: PropTypes.oneOf([
        'auto',
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    mediumMarginRight: PropTypes.oneOf([
        'auto',
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    mediumPadding: PropTypes.oneOf([
        'none',
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    mediumPaddingTop: PropTypes.oneOf([
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    mediumPaddingBottom: PropTypes.oneOf([
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    mediumPaddingLeft: PropTypes.oneOf([
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    mediumPaddingRight: PropTypes.oneOf([
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    smallDisplay: PropTypes.oneOf(['block', 'inline', 'flex']),
    smallAlignItems: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'baseline', 'stretch']),
    smallJustifyContent: PropTypes.oneOf([
        'flex-start',
        'flex-end',
        'center',
        'space-between',
        'space-around',
        'space-evenly',
    ]),
    smallFlexDirection: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
    smallBackground: PropTypes.oneOf([
        'light',
        'light-secondary',
        'light-tertiary',
        'dark',
        'dark-secondary',
        'primary',
    ]),
    smallBorder: PropTypes.oneOf(['light', 'light-secondary', 'dark', 'dark-secondary']),
    smallBorderRadius: PropTypes.oneOf(['regular', 'large']),
    smallMargin: PropTypes.oneOf([
        'auto',
        'none',
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    smallMarginTop: PropTypes.oneOf([
        'auto',
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    smallMarginBottom: PropTypes.oneOf([
        'auto',
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    smallMarginLeft: PropTypes.oneOf([
        'auto',
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    smallMarginRight: PropTypes.oneOf([
        'auto',
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    smallPadding: PropTypes.oneOf([
        'none',
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    smallPaddingTop: PropTypes.oneOf([
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    smallPaddingBottom: PropTypes.oneOf([
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    smallPaddingLeft: PropTypes.oneOf([
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    smallPaddingRight: PropTypes.oneOf([
        'micro',
        'x-small',
        'm-small',
        'small',
        'regular',
        'large',
        'x-large',
        'jumbo',
    ]),
    overflowY: PropTypes.oneOf(['auto', 'scroll', 'hidden', 'visible']),
    overflowX: PropTypes.oneOf(['auto', 'scroll', 'hidden', 'visible']),
    flex: PropTypes.oneOf(['1', '2', '3', '4', '5', '6', '7', '8', '9', 'auto', 'initial', 'none']),
    order: PropTypes.oneOf([
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        'first',
        'last',
        'none',
    ]),
    grow: PropTypes.oneOf(['0', '1']),
    shrink: PropTypes.oneOf(['0', '1']),
    alignSelf: PropTypes.oneOf(['auto', 'start', 'end', 'center', 'stretch', 'baseline']),
    opacity: PropTypes.oneOf([
        '0',
        '5',
        '10',
        '20',
        '25',
        '30',
        '40',
        '50',
        '60',
        '70',
        '75',
        '80',
        '90',
        '95',
        '100',
    ]),
};

Box.defaultProps = {
    component: 'div',
};

export default Box;
