import React from 'react';

function PortalLoginLogo() {
    return (
        <svg width="136" height="42" viewBox="0 0 136 42" fill="none">
            <path
                d="M51.9568 17.1164H47.39V19.586H51.702V22.8396H47.39V25.3876H51.9568V28.6412H43.5484V13.8628H51.9568V17.1164Z"
                fill="#393B3D"
            />
            <path
                d="M60.1949 13.8628L63.7621 22.8592L67.3489 13.8628H71.5237L65.2909 28.6412H62.2333L56.0201 13.8628H60.1949Z"
                fill="#393B3D"
            />
            <path
                d="M77.796 21.252C77.796 21.84 77.9071 22.3823 78.1292 22.8788C78.3514 23.3753 78.6519 23.8065 79.0308 24.1724C79.4097 24.5383 79.8475 24.8257 80.344 25.0348C80.8536 25.2308 81.3894 25.3288 81.9512 25.3288C82.5131 25.3288 83.0423 25.2308 83.5388 25.0348C84.0484 24.8257 84.4927 24.5383 84.8716 24.1724C85.2636 23.8065 85.5707 23.3753 85.7928 22.8788C86.0149 22.3823 86.126 21.84 86.126 21.252C86.126 20.664 86.0149 20.1217 85.7928 19.6252C85.5707 19.1287 85.2636 18.6975 84.8716 18.3316C84.4927 17.9657 84.0484 17.6848 83.5388 17.4888C83.0423 17.2797 82.5131 17.1752 81.9512 17.1752C81.3894 17.1752 80.8536 17.2797 80.344 17.4888C79.8475 17.6848 79.4097 17.9657 79.0308 18.3316C78.6519 18.6975 78.3514 19.1287 78.1292 19.6252C77.9071 20.1217 77.796 20.664 77.796 21.252ZM73.778 21.252C73.778 20.1544 73.9806 19.1352 74.3856 18.1944C74.7907 17.2405 75.3526 16.4108 76.0712 15.7052C76.7899 14.9996 77.6457 14.4508 78.6388 14.0588C79.645 13.6537 80.7491 13.4512 81.9512 13.4512C83.1403 13.4512 84.2379 13.6537 85.244 14.0588C86.2501 14.4508 87.1125 14.9996 87.8312 15.7052C88.563 16.4108 89.1314 17.2405 89.5364 18.1944C89.9415 19.1352 90.144 20.1544 90.144 21.252C90.144 22.3496 89.9415 23.3753 89.5364 24.3292C89.1314 25.27 88.563 26.0932 87.8312 26.7988C87.1125 27.5044 86.2501 28.0597 85.244 28.4648C84.2379 28.8568 83.1403 29.0528 81.9512 29.0528C80.7491 29.0528 79.645 28.8568 78.6388 28.4648C77.6457 28.0597 76.7899 27.5044 76.0712 26.7988C75.3526 26.0932 74.7907 25.27 74.3856 24.3292C73.9806 23.3753 73.778 22.3496 73.778 21.252Z"
                fill="#393B3D"
            />
            <path
                d="M98.5682 13.8628V25.3876H103.174V28.6412H94.7266V13.8628H98.5682Z"
                fill="#393B3D"
            />
            <path
                d="M109.745 13.8628L113.312 22.8592L116.899 13.8628H121.073L114.841 28.6412H111.783L105.57 13.8628H109.745Z"
                fill="#393B3D"
            />
            <path
                d="M133.677 17.1164H129.11V19.586H133.422V22.8396H129.11V25.3876H133.677V28.6412H125.268V13.8628H133.677V17.1164Z"
                fill="#393B3D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.336258 31.4919L0.336258 10.5081L18.2 2.13023e-07L36.0637 10.5081L36.0637 31.4919L18.2 42L0.336258 31.4919ZM2.32112 30.3567L18.2 39.6972L34.0789 30.3567L34.0789 11.6433L18.2 2.3028L2.32112 11.6433L2.32112 30.3567Z"
                fill="#3168F6"
            />
            <path
                d="M19.4682 21.7218L30.9889 15.0823L30.9887 19.5083L19.4681 26.1478L19.4682 21.7218Z"
                fill="#3168F6"
            />
            <path
                d="M19.4681 30.5738L30.9885 23.9344V28.3605L19.4679 35L19.4681 30.5738Z"
                fill="#3168F6"
            />
            <path
                d="M18.4626 7L29.9836 13.6387L26.1432 15.8517L14.6222 9.21292L18.4626 7Z"
                fill="#3168F6"
            />
            <path
                d="M22.3029 9.21292L10.7819 15.8517L6.94156 13.6387L18.4626 7L22.3029 9.21292Z"
                fill="#3168F6"
            />
            <path
                d="M29.9836 13.6388L18.4626 20.2775L14.6222 18.0646L26.1432 11.4258L29.9836 13.6388Z"
                fill="#3168F6"
            />
            <path
                d="M5.93661 28.3605L5.93626 15.0823L9.77642 17.2954L9.77695 30.5736L5.93661 28.3605Z"
                fill="#3168F6"
            />
            <path
                d="M5.93661 23.9344L17.4571 30.5738L17.4573 35L5.93661 28.3605V23.9344Z"
                fill="#3168F6"
            />
            <path
                d="M13.6167 19.5087L17.4569 21.7218L17.4571 26.1478L13.6167 23.9347V19.5087Z"
                fill="#3168F6"
            />
            <path
                d="M49.4832 39.7849H51.1926L48.0453 32.7463H46.4364L43.2992 39.7849H44.9684L45.5918 38.2767H48.8597L49.4832 39.7849ZM46.1147 37.0399L47.2308 34.3451L48.3469 37.0399H46.1147Z"
                fill="#393B3D"
            />
            <path
                d="M56.0981 39.9056C57.3248 39.9056 58.3404 39.4632 59.0041 38.6487L57.9583 37.6834C57.4857 38.2364 56.8925 38.518 56.1886 38.518C54.8714 38.518 53.9362 37.5929 53.9362 36.2656C53.9362 34.9383 54.8714 34.0132 56.1886 34.0132C56.8925 34.0132 57.4857 34.2948 57.9583 34.8378L59.0041 33.8725C58.3404 33.068 57.3248 32.6256 56.1082 32.6256C53.9161 32.6256 52.2872 34.144 52.2872 36.2656C52.2872 38.3873 53.9161 39.9056 56.0981 39.9056Z"
                fill="#393B3D"
            />
            <path
                d="M64.2303 39.9056C65.457 39.9056 66.4726 39.4632 67.1363 38.6487L66.0905 37.6834C65.6179 38.2364 65.0247 38.518 64.3208 38.518C63.0035 38.518 62.0684 37.5929 62.0684 36.2656C62.0684 34.9383 63.0035 34.0132 64.3208 34.0132C65.0247 34.0132 65.6179 34.2948 66.0905 34.8378L67.1363 33.8725C66.4726 33.068 65.457 32.6256 64.2403 32.6256C62.0483 32.6256 60.4193 34.144 60.4193 36.2656C60.4193 38.3873 62.0483 39.9056 64.2303 39.9056Z"
                fill="#393B3D"
            />
            <path
                d="M72.4027 39.9056C74.6148 39.9056 76.2538 38.3672 76.2538 36.2656C76.2538 34.1641 74.6148 32.6256 72.4027 32.6256C70.1805 32.6256 68.5515 34.1741 68.5515 36.2656C68.5515 38.3571 70.1805 39.9056 72.4027 39.9056ZM72.4027 38.518C71.1458 38.518 70.2006 37.603 70.2006 36.2656C70.2006 34.9283 71.1458 34.0132 72.4027 34.0132C73.6596 34.0132 74.6048 34.9283 74.6048 36.2656C74.6048 37.603 73.6596 38.518 72.4027 38.518Z"
                fill="#393B3D"
            />
            <path
                d="M81.5102 39.9056C83.5112 39.9056 84.6977 38.7593 84.6977 36.6879V32.7463H83.0889V36.6276C83.0889 37.9649 82.5158 38.518 81.5203 38.518C80.5349 38.518 79.9517 37.9649 79.9517 36.6276V32.7463H78.3227V36.6879C78.3227 38.7593 79.5092 39.9056 81.5102 39.9056Z"
                fill="#393B3D"
            />
            <path
                d="M92.059 32.7463V37.0198L88.5598 32.7463H87.2124V39.7849H88.8213V35.5115L92.3305 39.7849H93.6679V32.7463H92.059Z"
                fill="#393B3D"
            />
            <path
                d="M97.6962 39.7849H99.3251V34.0736H101.578V32.7463H95.4438V34.0736H97.6962V39.7849Z"
                fill="#393B3D"
            />
        </svg>
    );
}

export default PortalLoginLogo;
