import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'uikit/box';
import { Typography } from 'uikit/typography';

const PortalSignupFormError = ({ error }) => (
    <Box
        marginBottom="regular"
        padding="x-small"
        style={{
            borderLeft: `2px solid #e3002c`,
            background: 'rgba(208, 44, 53, 0.05)',
        }}
    >
        <Typography fontStyle="small" theme="warning" fontWeight="bold">
            Looks like some fields are missing or incorrect. See the details below.
        </Typography>
        {error && (
            <Box marginTop="x-small">
                <Typography fontStyle="small" theme="warning" fontWeight="bold">
                    {error}
                </Typography>
            </Box>
        )}
    </Box>
);

PortalSignupFormError.propTypes = {
    error: PropTypes.string,
};

export default PortalSignupFormError;
