import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Hyperlink from './hyperlink';
import { Icon } from '../../icons';

function KnowledgeBaseLink({ label, url, isInline, hideIcon }) {
    const getLabel = () => (
        <>
            {!hideIcon && <Icon icon="courses" className="knowledge-base-link-icon" />}
            <span className="knowledge-base-link-label">{label}</span>
        </>
    );

    return (
        <Hyperlink
            className={classnames('knowledge-base-link', { 'is-inline': isInline })}
            label={getLabel()}
            url={url}
        />
    );
}

KnowledgeBaseLink.propTypes = {
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    isInline: PropTypes.bool,
    hideIcon: PropTypes.bool,
};

export default KnowledgeBaseLink;
