import { useMemo } from 'react';

function useIsSignupButtonDisabled(model) {
    const isSignupButtonDisabled = useMemo(() => {
        const values = _.values(model);
        const emptyValues = _.filter(values, value => !value);
        return emptyValues.length > 0;
    }, [model]);
    return isSignupButtonDisabled;
}

export default useIsSignupButtonDisabled;
