import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const type = {
    primary: 'primary',
    secondary: 'secondary',
};
function Hyperlink({ className, label, url, shouldOpenInNewWindow, type }) {
    return (
        <a
            className={classnames('link', `is-${type}`, className)}
            href={url}
            {...(shouldOpenInNewWindow && { target: '_blank', rel: 'noreferrer' })}
        >
            {label}
        </a>
    );
}

Hyperlink.propTypes = {
    className: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    url: PropTypes.string.isRequired,
    shouldOpenInNewWindow: PropTypes.bool,
    type: PropTypes.string,
};

Hyperlink.defaultProps = {
    shouldOpenInNewWindow: true,
    type: type.primary,
};

export default Hyperlink;
