import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'uikit/box';
import { Typography } from 'uikit/typography';
import { Button } from 'uikit/button';
import { Hyperlink } from 'uikit/link';
import useIsSignupButtonDisabled from '../helpers/useIsSignupButtonDisabled';
import getLoginData from '../helpers/getLoginData';
import PortalLoginLogo from './portalLoginLogo';
import PortalTextInputField from '../fields/portalTextInputField';
import PortalSignupFormError from './portalSignupFormError';

function PortalLoginForm({ onSubmit, isSubmitting, error, clearError }) {
    const [model, setModel] = useState({
        email: '',
        password: '',
    });

    const [_errors, _setErrors] = useState({});

    const isLoginDisabled = useIsSignupButtonDisabled(model);

    const onFieldChange = (key, value) => {
        setModel(model => _.assign({}, model, { [key]: value }));
    };

    const onSubmitClicked = () => {
        const { data, errors } = getLoginData(model);
        clearError();
        _setErrors(errors);
        if (_.isEmpty(errors)) {
            onSubmit(data);
        }
    };

    return (
        <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            marginTop="auto"
            padding="small"
        >
            <Box display="flex" alignItems="center" marginBottom="large">
                <PortalLoginLogo />
            </Box>
            <Box
                padding="x-large"
                paddingBottom="jumbo"
                boxShadow="large"
                background="light"
                borderRadius="regular"
                style={{ width: '450px' }}
            >
                <Typography fontStyle="title-2" theme="light" fontWeight="bold" spacing="micro">
                    Account Management
                </Typography>
                <Typography
                    fontStyle="small"
                    theme="light-secondary"
                    fontWeight="regular"
                    spacing="large"
                >
                    Sign in with your Evolve Account Management credentials.
                </Typography>
                {(!_.isEmpty(_errors) || error) && <PortalSignupFormError error={error} />}
                <PortalTextInputField
                    type="email"
                    title="Email"
                    value={model.email}
                    onChange={value => onFieldChange('email', value)}
                    error={_errors.email}
                />
                <PortalTextInputField
                    type="password"
                    title="Password"
                    value={model.password}
                    onChange={value => onFieldChange('password', value)}
                    error={_errors.password}
                    marginBottom="x-small"
                />
                <Box marginBottom="regular">
                    <Hyperlink
                        className="font-style-x-small"
                        label="Forgot Password?"
                        url="/passwordReset"
                        shouldOpenInNewWindow={false}
                        type="secondary"
                    />
                </Box>
                <Button
                    className="portal-signup-submit-button"
                    type="primary"
                    isFullWidth={true}
                    text={isSubmitting ? 'Logging In...' : 'Login'}
                    isDisabled={isLoginDisabled || isSubmitting}
                    onClick={onSubmitClicked}
                />
            </Box>
        </Box>
    );
}

PortalLoginForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    error: PropTypes.string,
    clearError: PropTypes.func.isRequired,
};

export default PortalLoginForm;
