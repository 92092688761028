import React from 'react';
import { Box } from 'uikit/box';
import { Typography } from 'uikit/typography';
import { usePublicPath } from '../../../publicPath';

function PortalBranding() {
    const publicPath = usePublicPath();

    return (
        <Box
            component="a"
            padding="regular"
            paddingTop="large"
            marginTop="auto"
            href="https://www.intellum.com/"
            target="_blank"
            rel="noreferrer"
        >
            <Typography
                fontStyle="micro"
                theme="light"
                fontWeight="regular"
                textAlign="center"
                spacing="x-small"
                style={{
                    letterSpacing: '1.4px',
                }}
            >
                POWERED BY
            </Typography>
            <Box display="flex" justifyContent="center">
                <img
                    src={`${publicPath}/assets/intellum.png`}
                    alt="Evolve Logo"
                    style={{ height: '34px', width: 'auto' }}
                />
            </Box>
        </Box>
    );
}

export default PortalBranding;
