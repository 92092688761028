import React, { useState } from 'react';
import axios from 'axios';
import { Box } from 'uikit/box';
import PortalLoginForm from '../components/portalLoginForm';
import PortalBranding from '../components/portalBranding';

function PortalLoginContainer() {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');

    const onSubmit = async data => {
        setIsSubmitting(true);
        setError('');

        try {
            await axios.post('/api/login', data);
            window.location.href = '/';
        } catch (error) {
            const errorMessage = _.get(error, 'response.data.message', 'Something went wrong');
            setError(errorMessage);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            background="light-secondary"
            style={{ minHeight: '100vh' }}
        >
            <PortalLoginForm
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                error={error}
                clearError={() => setError('')}
            />
            <PortalBranding />
        </Box>
    );
}

export default PortalLoginContainer;
